import React, { useState, useEffect, useContext } from 'react';
import VinLookupService from '../services/vin-lookup-service/vin-lookup-service';
import { APIHelperContext } from './api-helper-context';

export const VinContext = React.createContext<any>({
  vin: null,
});

interface Props {
  children: React.ReactNode;
}

export const VinContextProvider = ({ children }: Props) => {
  const [paidSubscriptions, setPaidSubscriptions] = useState<null | any>(null);
  const [freeSubscriptions, setFreeSubscriptions] = useState<null | any>(null);
  const [vehicleModel, setVehicleModel] = useState<null | any>(null);
  const [vehicleMake, setVehicleMake] = useState<null | any>(null);
  const [vehicleYear, setVehicleYear] = useState<null | any>(null);
  const [vin, setVin] = useState<string>();
  const [apiHelper] = useContext(APIHelperContext);
  const [vinNotFound, setVinNotFound] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (vin) {
      const vinLookupService = new VinLookupService();
      const data = {
        vin: vin,
        language: apiHelper.languageCountry(),
      };
      vinLookupService.request(data, apiHelper).then(response => {
        if ((response && response.status == 'error') || response.length == 0) {
          setShowError(true);
          return setVinNotFound(true);
        }
        setVinNotFound(false);
        setFreeSubscriptions(response.freeServices);
        setPaidSubscriptions(response.paidServices);
        setVehicleMake(response.make);
        setVehicleModel(response.model);
        setVehicleYear(response.year);
      });
    }
  }, [vin]);

  const clearVinConnectedServices = () => {
    setPaidSubscriptions(null);
    setFreeSubscriptions(null);
    setVehicleMake(null);
    setVehicleModel(null);
    setVehicleYear(null);
    setVin(null);
  };

  return (
    <VinContext.Provider
      value={[
        {
          vin,
          setVin,
          paidSubscriptions,
          setPaidSubscriptions,
          freeSubscriptions,
          setFreeSubscriptions,
          vehicleMake,
          setVehicleMake,
          vehicleModel,
          setVehicleModel,
          vehicleYear,
          setVehicleYear,
          clearVinConnectedServices,
          vinNotFound,
          setVinNotFound,
          showError,
          setShowError,
        },
      ]}
    >
      {children}
    </VinContext.Provider>
  );
};
