// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nWqvpt4ULixwg_fZQq_Z{color:#333;text-align:left}.nWqvpt4ULixwg_fZQq_Z .fc9JLFtHpiqGnGM1KwQW{font-size:14px;font-weight:900;line-height:24px;margin-bottom:5px}.nWqvpt4ULixwg_fZQq_Z .tBsqB8eD_mw1jrgfqL5i{font-size:14px;line-height:20px;font-weight:400;padding:0px 0px 12px 0px}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/text-msg-tc/text-msg-tc.module.scss"],"names":[],"mappings":"AACA,sBACE,UAAA,CACA,eAAA,CAEA,4CACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,iBAAA,CAGF,4CACE,cAAA,CACA,gBAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":["@import \"main.scss\";\n.SmsTCs {\n  color: #333333;\n  text-align: left;\n\n  .header {\n    font-size: 14px;\n    font-weight: 900;\n    line-height: 24px;\n    margin-bottom: 5px;\n  }\n\n  .subtext {\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n    padding: 0px 0px 12px 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SmsTCs": `nWqvpt4ULixwg_fZQq_Z`,
	"header": `fc9JLFtHpiqGnGM1KwQW`,
	"subtext": `tBsqB8eD_mw1jrgfqL5i`
};
export default ___CSS_LOADER_EXPORT___;
