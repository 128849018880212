import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';
import ServiceHandler from '../service-handler';


// This service processes sign and order requests and returns a status

export default class FinancingService {
  public async request(apiHelper: APIHelper, data: any) {
    return await post('FINANCING_LOOKUP', null, null, data, null, apiHelper, ServiceHandler.ConfigService.DSEC_BASE_URL);
  }
}