import React, { useContext, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'; // the locale you want
import cx from '../../../utils/classnames';
import styles from './datepicker-with-label.module.scss';
import { TranslationContext } from '../../../context/translations-context';

interface Props {
  id: string;
  label: string;
  selectedDate?: Date;
  className?: string;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (date: Date, evnt: React.SyntheticEvent<any, Event>) => void;
  setEditingCalendar?: (open: boolean) => void;
}

export const DatePickerWithLabel = ({ id, label, selectedDate, className, maxDate = new Date(), minDate, onChange, setEditingCalendar }: Props) => {
  const [{ language }] = useContext(TranslationContext);
  useEffect(() => {
    const picker = document.getElementById(id) as HTMLElement;
    picker.insertAdjacentHTML('afterend', `<label htmlFor='${id}' class='${styles.datePickerLabel}'>${label}</label>`);
  }, []);

  registerLocale('fr', fr); // register it with the name you want

  return (
    <div className={styles.datePickerGroup}>
      <DatePicker
        id={id}
        locale={language.startsWith('fr') ? 'fr' : undefined}
        name={id}
        selected={selectedDate}
        onChange={onChange}
        onCalendarClose={() => {
          setEditingCalendar && setEditingCalendar(false);
        }}
        onCalendarOpen={() => {
          setEditingCalendar && setEditingCalendar(true);
        }}
        dateFormat={language.startsWith('fr') ? 'yyyy/MM/dd' : 'MM/dd/yyyy'}
        className={cx(styles.datePickerControl, className)}
        placeholderText='*'
        popperPlacement='bottom'
        maxDate={maxDate}
        minDate={minDate}
        formatWeekDay={(nameOfDay: string) => nameOfDay.substr(0, 3)}
        renderCustomHeader={({ date, increaseMonth, decreaseMonth, nextMonthButtonDisabled, prevMonthButtonDisabled }) => (
          <div className='react-datepicker__current-month'>
            {date.toLocaleString(language, { month: 'long' })}
            <span> {date.getFullYear()}</span>
            <button
              type='button'
              className='react-datepicker__navigation react-datepicker__navigation--previous'
              aria-label='Previous Month'
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />
            <button
              type='button'
              className='react-datepicker__navigation react-datepicker__navigation--next'
              aria-label='Next Month'
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </div>
        )}
      />
      <i className={styles.datePickerIcon}></i>
    </div>
  );
};
