/* eslint-disable no-console */
import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { MemberView } from './views/member-view/member-view';
import { AuthCallback } from './views/auth-login-logout/auth-callback';
import { Logout } from './views/auth-login-logout/logout';
import { LogoutCallback } from './views/auth-login-logout/logout-callback';
import { AuthRoute } from './components/common/auth-route/auth-route';
import { AuthTokenRenew } from './views/auth-login-logout/auth-token-renew';
import { ReportsView } from './views/reports-view/reports-view';
import { TrainingView } from './views/training-view/training-view';
import SiteCodeForm from './views/site-code-form/site-code-form';
import { TransactionsView } from './views/transactions-view/transactions-view';
import { useAmplitude } from './hooks/useAmplitude';
import ServiceHandler from './services/service-handler';
import amplitude from 'amplitude-js';
import { MaintenanceBreakView } from './views/maintenance-break-view/maintenance-break-view';
import { PmpFormView } from './views/pmp-form-view/pmp-form-view';
import { TranslationContext } from './context/translations-context';
import { FinFormView } from './views/financing-form-view/financing-form-view';
import { DealerFormView } from './views/financing-form-view/dealer-auth-form-view';
import { IncentivesView } from './views/incentives-view/incentives-view';

const AppRoutes = () => {
  const [logAmplitudeEvent] = useAmplitude();
  const { pathname } = useLocation();
  const [translation] = useContext(TranslationContext);

  useEffect(() => {
    if (ServiceHandler.AuthenticationService.getIdToken()) {
      logAmplitudeEvent(`${pathname.replace(/\W/g, ' ').trim()} viewed`, translation);
    }

    (async () => {
      const response = await fetch(`meta.json?t=${new Date().getTime()}`, { cache: 'no-store' });
      const remoteVer = await response.text();

      const ver = sessionStorage.getItem('pfs_app_version') || global['pfs_app_version'];

      if (ver.trim() !== remoteVer.trim()) {
        const s = JSON.stringify({
          old: ver,
          new: remoteVer,
          session: sessionStorage.getItem('pfs_app_version') || 'none',
          global: global['pfs_app_version'] || 'none',
        });
        amplitude.getInstance().logEvent('pfs cache clear', JSON.parse(s));

        if (window.caches) {
          const names = await window.caches.keys();
          await Promise.all(names.map(name => window.caches.delete(name)));
        }

        window.location.reload();
      }
      sessionStorage.setItem('pfs_app_version', remoteVer.trim());
    })();

    document.addEventListener('click', handleLinkClick);
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [pathname]);

  const handleLinkClick = e => {
    if (e.target.tagName == 'A' || e.target.tagName == 'SPAN') {
      logAmplitudeEvent('link pressed', translation, { text: e.target.innerText });
    }
  };

  return (
    <Routes>
      <Route path='/auth-callback' element={<AuthCallback />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/logout-callback' element={<LogoutCallback />} />
      <Route path='/auth-token-renew' element={<AuthTokenRenew />} />
      <Route path='/change-site-code' element={<SiteCodeForm />} />
      <Route path='/maintenance' element={<MaintenanceBreakView />} />

      <Route path='/' element={<AuthRoute />}>
        <Route path='transactions' element={<TransactionsView />} />
        <Route path='training' element={<TrainingView />} />
        <Route path='reports' element={<ReportsView />} />
        <Route path='incentives' element={<IncentivesView />} />
        <Route path='pmp-form' element={<PmpFormView />} />
        <Route path='fin-auth-form' element={<FinFormView />} />
        <Route path='dealer-auth-form' element={<DealerFormView />} />
        <Route path='*' element={<MemberView />} />
        <Route index element={<MemberView />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
