import React, { useState, useContext } from 'react';
import { HeaderView, HEADER_TAB_STATES } from '../../components/sections/header-bar/header-bar';
import { Header } from '../../components/common/header/header';
// import TabBar from '../../components/common/tabbar/tabbar';
// import Tab from '../../components/common/tabbar/tab';

import styles from './incentives-view.module.scss';
import { TranslationContext } from '../../context/translations-context';
// import { SiteContext } from '../../context/site-context';
// import { TabReportsReimbursement } from '../../components/reports/reimbursement/reimbursement';
// import { TabReportsLiability } from '../../components/reports/liability/liability';
// import { TabReportsGoodwill } from '../../components/reports/goodwill/goodwill';
// import { TabReportsVehiclePurchase } from '../../components/reports/vehicle-purchase/vehicle-purchase';
// import { TabReportsInactiveMember } from '../../components/reports/inactive-members/inactive-members';
// import { TabReportsDealerCompensation } from '../../components/reports/dealer-compensation/dealer-compensation';
// import { DealerContext } from '../../context/dealer-context';

// enum TAB_STATES {

// }

export const IncentivesView = () => {
  const [{ t }] = useContext(TranslationContext);
//   const [{ isCanada }] = useContext(SiteContext);
//   const [activeTabId, setActiveTabId] = useState(TAB_STATES.TAB_REIMBURSEMENT as string);
//   const [{ isConnectedServiceDealer }] = useContext(DealerContext);

//   const tabContents = {};
//   tabContents[TAB_STATES.TAB_REIMBURSEMENT] = TabReportsReimbursement;
//   tabContents[TAB_STATES.TAB_LIABILITY] = TabReportsLiability;
//   tabContents[TAB_STATES.TAB_GOODWILL] = TabReportsGoodwill;
//   tabContents[TAB_STATES.TAB_VEHICLE] = TabReportsVehiclePurchase;
//   tabContents[TAB_STATES.TAB_INACTIVE_MEMBERS] = TabReportsInactiveMember;
//   tabContents[TAB_STATES.TAB_DEALER_COMPENSATION] = TabReportsDealerCompensation;

//   const TabContent = tabContents[activeTabId];

  return (
    <>
    <HeaderView tabID={HEADER_TAB_STATES.TAB_INCENTIVES} />
    <Header title={t('Connected Services Incentives')} className={styles.contentWrapper}>
        <div className={styles.tabContent}>
            {t('Coming Soon')}
        </div>
    </Header>
    </>
  );
};
