// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I6g1zvJwWt1UIvvryOle{margin:30px 50px}.vlJ3Kkh4NVq6nk8OmMMX{background:#eee;padding:20px}.L9rFaC0t92D79Bv_ekFH{border:solid 2px #ccc;border-top:none;min-height:100vh;padding:0px}.BA7kUW7HtN6MVOocfxW1 div a{font-size:16px !important}.YRX9WtRSfU083S3ePxp_{background:#ff0}.mo4E0h_UBlxIQd48zqM_{border:1px #008200;border-radius:var(--radius-SM, 8px);background-color:#008200;height:20px;width:56px;font-size:15px;color:#fff;padding:2px 15px;margin-left:10px}._8PtgHSKZ5tbVKBPKPQ1{padding:20px 20px 5px}.hTKlctmzhbStVfkptrSA{font-weight:900;font-size:18px}.WgbJkDew0Fr10TVX5Pfk{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/views/member-view/member-view.module.scss"],"names":[],"mappings":"AACA,sBACE,gBAAA,CAGF,sBACE,eAAA,CACA,YAAA,CAGF,sBACE,qBAAA,CACA,eAAA,CACA,gBAAA,CACA,WAAA,CAEF,4BACE,yBAAA,CAGF,sBACE,eAAA,CAEF,sBACE,kBAAA,CACA,mCAAA,CACA,wBAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,UAAA,CACA,gBAAA,CACA,gBAAA,CAGF,sBACE,qBAAA,CAEF,sBACE,eAAA,CACA,cAAA,CAGF,sBACE,YAAA,CACA,qBAAA","sourcesContent":["@import \"main.scss\";\n.contentWrapper {\n  margin: 30px 50px;\n}\n\n.columnContainer {\n  background: #eee;\n  padding: 20px;\n}\n\n.tabContent {\n  border: solid 2px #ccc;\n  border-top: none;\n  min-height: 100vh;\n  padding: 0px;\n}\n.links div a {\n  font-size: 16px !important;\n}\n\n.expired {\n  background: yellow;\n}\n.newBox {\n  border: 1px #008200;\n  border-radius: var(--radius-SM, 8px);\n  background-color: #008200;\n  height: 20px;\n  width: 56px;\n  font-size: 15px;\n  color: #ffffff;\n  padding: 2px 15px;\n  margin-left: 10px;\n}\n\n.vinSearchContainer {\n  padding: 20px 20px 5px;\n}\n.quickSearch {\n  font-weight: 900;\n  font-size: 18px;\n}\n\n.vinTab {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `I6g1zvJwWt1UIvvryOle`,
	"columnContainer": `vlJ3Kkh4NVq6nk8OmMMX`,
	"tabContent": `L9rFaC0t92D79Bv_ekFH`,
	"links": `BA7kUW7HtN6MVOocfxW1`,
	"expired": `YRX9WtRSfU083S3ePxp_`,
	"newBox": `mo4E0h_UBlxIQd48zqM_`,
	"vinSearchContainer": `_8PtgHSKZ5tbVKBPKPQ1`,
	"quickSearch": `hTKlctmzhbStVfkptrSA`,
	"vinTab": `WgbJkDew0Fr10TVX5Pfk`
};
export default ___CSS_LOADER_EXPORT___;
