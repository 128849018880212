import React, { useState, useContext } from 'react';
import styles from './vin-search.module.scss';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import utils from '../../../utils/utils.module.scss';
import cx from '../../../utils/classnames';
import { VinContext } from '../../../context/vin-lookup-context';
import { TranslationContext } from '../../../context/translations-context';
import { ErrorText } from '../../common/error-text/error-text';

export const VinSearch = ({ onSearch }) => {
  const [{ t }] = useContext(TranslationContext);
  const [vinToSearch, setVinToSearch] = useState<string>();
  const [{ setVin, showError, setShowError }] = useContext(VinContext);

  return (
    <form
      className={styles.formContainer}
      onSubmit={e => {
        e.preventDefault();
        setVin(vinToSearch);
        onSearch(vinToSearch);
        setVinToSearch('');
      }}
    >
      <InputWithLabel
        label={t('Search by VIN')}
        id='vinsearch'
        value={vinToSearch}
        onChange={(e: any) => {
          setVinToSearch(e.target.value.trim());
          setShowError(false);
        }}
        className={cx(utils.searchInput)}
      />
      {showError && <ErrorText text={t('VIN Not Found')} className={styles.error} />}

      <button disabled={!vinToSearch} type='submit' className={cx(utils.buttonPrimary, styles.vinSearchBtn)}>
        Search
      </button>
    </form>
  );
};

export default VinSearch;
