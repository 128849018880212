import React, { useContext } from 'react';
import { HeaderView, HEADER_TAB_STATES } from '../../components/sections/header-bar/header-bar';
import { Header } from '../../components/common/header/header';

import { FontAwesome } from '../../components/common/font-awesome/font-awesome';
import styles from './training-view.module.scss';
import { TranslationContext } from '../../context/translations-context';
import { SiteContext } from '../../context/site-context';

export const TrainingView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ isCanada }] = useContext(SiteContext);

  return (
    <>
      <HeaderView tabID={HEADER_TAB_STATES.TAB_TRAINING} />
      <Header title={t('Training Documents')} className={styles.contentWrapper}>
        <p>
          <FontAwesome icon='arrow-up-right-from-square' />{' '}
          <a href={isCanada() ? 'https://www.fpracademyca.ca' : 'https://www.FPRAcademy.com'} target='blank'>
            {t('FPR Academy')}
          </a>
        </p>
        <p>
          <FontAwesome icon='arrow-up-right-from-square' />{' '}
          <a
            href={
              isCanada()
                ? 'https://www.laracademyca.ca'
                : 'https://www.fmcdealer.dealerconnection.com/sites/ford_lm/Sales/lincolntools/own/lar/Pages/default.aspx'
            }
            target='blank'
          >
            {t('LAR Academy')}
          </a>
        </p>
      </Header>
      <div className={styles.version}>Version: {global['pfs_app_version']}</div>
    </>
  );
};
