import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { Body, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import { TranslationContext } from '../../../context/translations-context';
import styles from './docusign-dialog.module.scss';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';


export const DocuSignPassDialog = () => {
  const [{ t }] = useContext(TranslationContext);

  return (
    <ModalDialog title={t('DOCUSIGN_PASS_TITLE')} width='33vw' height='15vw' className={styles.modal}>
      <Body>
        <div className={styles.body}>{t('DOCUSIGN_PASS_BODY')}</div>
      </Body>
      <Link to='/'>
        <button className={cx(utils.buttonPrimary, styles.successBtn)}>
          {t('DOCUSIGN_CTA')}
        </button>
      </Link>
    </ModalDialog>
  );
};