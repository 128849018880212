// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aYvlGkhJ8LNEG0rKg9NP{margin:60px 50px}.ylHqGCfFQuI9HJHqAbCF{border:solid 2px #ccc;min-height:100vh;padding:40px}`, "",{"version":3,"sources":["webpack://./src/views/incentives-view/incentives-view.module.scss"],"names":[],"mappings":"AACA,sBACI,gBAAA,CAEF,sBACE,qBAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":["@import \"main.scss\";\n.contentWrapper {\n    margin: 60px 50px;\n  }\n  .tabContent {\n    border: solid 2px #ccc;\n    min-height: 100vh;\n    padding: 40px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `aYvlGkhJ8LNEG0rKg9NP`,
	"tabContent": `ylHqGCfFQuI9HJHqAbCF`
};
export default ___CSS_LOADER_EXPORT___;
