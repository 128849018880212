// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GDegtjHjHgFNDE6hsQkw{margin:15px 0 45px 0}.iReWdrp_gOPRV_KFuc1z{margin:7px 45px 0 0}.L2rUHL9awUnu8eS5zTSZ{text-decoration:none;pointer-events:all;display:inline-block}.wPfFZjInIdQ3nh0COnCU{font-size:16px;line-height:24px;font-weight:400}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/docusign/docusign-dialog.module.scss"],"names":[],"mappings":"AACA,sBACE,oBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,oBAAA,CACA,kBAAA,CACA,oBAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,eAAA","sourcesContent":["@import \"main.scss\";\n.body {\n  margin: 15px 0 45px 0;\n}\n\n.button {\n  margin: 7px 45px 0 0;\n}\n\n.successBtn {\n  text-decoration: none;\n  pointer-events: all;\n  display: inline-block;\n}\n\n.subtext {\n  font-size: 16px;\n  line-height: 24px;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `GDegtjHjHgFNDE6hsQkw`,
	"button": `iReWdrp_gOPRV_KFuc1z`,
	"successBtn": `L2rUHL9awUnu8eS5zTSZ`,
	"subtext": `wPfFZjInIdQ3nh0COnCU`
};
export default ___CSS_LOADER_EXPORT___;
