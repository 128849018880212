// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ycbqw2aJiIsbJ7YSMdYA{text-align:center;color:#1f3047;font-size:16px;font-weight:700;line-height:24px}.Ugl7J7V1SGmHwkfyiN6H{text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/dialogs/inactive-member/inactive-member.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,iBAAA","sourcesContent":["@import \"main.scss\";\n.subtext {\n  text-align: center;\n  color: #1F3047;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 24px;\n}\n\n.QR {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtext": `ycbqw2aJiIsbJ7YSMdYA`,
	"QR": `Ugl7J7V1SGmHwkfyiN6H`
};
export default ___CSS_LOADER_EXPORT___;
