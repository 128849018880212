import React, { useContext, useEffect, useState } from 'react';
import { CtaButton } from '../../common/cta/cta';
import { Body, Buttons, ModalDialog } from '../../common/modal-dialog/modal-dialog';
import { TranslationContext } from '../../../context/translations-context';

const DEALER_ACKNOWLEDGE_DIALOG = 'DEALER_ACKNOWLEDGE_DIALOG';

export const DealerAcknowledgeDialog = () => {
  const [show, setShow] = useState(sessionStorage.getItem(DEALER_ACKNOWLEDGE_DIALOG) != 'true');
  const [{ t }] = useContext(TranslationContext);

  useEffect(() => {
    const dialogShown = sessionStorage.getItem(DEALER_ACKNOWLEDGE_DIALOG);
    if (dialogShown === 'true') {
      setShow(false);
    }
  }, []);

  const setShowDialog = () => {
    setShow(false);
    sessionStorage.setItem(DEALER_ACKNOWLEDGE_DIALOG, 'true');
  };

  if (!show) {
    return null;
  }
  
  return (
    <>
      {show && (
        <ModalDialog title={t('Dealer Acknowledgment')} width='30vw'>
          <Body>
            <div>{t('DEALER_ACKNOWLEDGE')}</div>
          </Body>
          <Buttons>
            <CtaButton label='I Accept' onClick={setShowDialog} />
          </Buttons>
        </ModalDialog>
      )}
    </>
  );
};
