import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';
import ServiceHandler from '../service-handler';

// This service returns all the available paid subscriptions from SSP for VIN

export default class ConnectedServicesService {
  static response: any;
  public async request(data: any, apiHelper: APIHelper) {
    return await post('CONNECTED_SERVICES', null, null, data, null, apiHelper, ServiceHandler.ConfigService.DSEC_BASE_URL);
  }
}
