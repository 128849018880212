import React, { useContext, useEffect, useState } from 'react';
import { FinancingPrefillContext } from '../../context/financing-prefill-context';
import { CustomerContext } from '../../context/customer-context';
import { TranslationContext } from '../../context/translations-context';
import styles from './financing-form-view.module.scss';
import cx from '../../utils/classnames';
import utils from '../../utils/utils.module.scss';
import { SiteContext } from '../../context/site-context';
import { DatePickerWithLabel } from '../../components/common/datepicker-with-label/datepicker-with-label';
import { Link } from 'react-router-dom';

interface Props {
  setDate?(date: Date): void;
  date?: Date;
  onChange?: (date: Date, evnt: React.SyntheticEvent<any, Event>) => void;
}

export const FinFormView = ({ setDate, onChange }: Props) => {
  const [{ t, languageCountry }] = useContext(TranslationContext);
  const [{ rewardProgram }] = useContext(CustomerContext);
  const { vin, setVin, modelYear, setModelYear, modelName, setModelName, trim, setTrim, purchaseDate, setPurchaseDate, firstName, setFirstName, lastName, setLastName, email, setEmail, phoneNumber, setPhoneNumber, zipCode, setZipCode, paymentSource, setPaymentSource, connectedService, setConnectedService, productDetails, ratePlan, setRatePlan, salePrice, setSalePrice, dealershipName, setDealershipName, dealershipPACode, setDealershipPACode, dealershipState, setDealershipState, employeeName, setEmployeeName, employeeTitle, setEmployeeTitle, employeePhoneNumber, setEmployeePhoneNumber, employeeEmail, setEmployeeEmail } = useContext(FinancingPrefillContext);
  const [inputError, setInputError] = useState<null | string>(null);
  const [emailInputError, setEmailInputError] = useState<null | string>(null);
  const [{ isCanada }] = useContext(SiteContext);
  const [connectedServiceSelected, setConnectedServiceSelected] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const country = isCanada() ? 'ca' : 'us';
  const isLAR = () => rewardProgram == 'L';

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);
  
  function validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const formatPhone = (phoneNumber: string) =>
    phoneNumber ? (phoneNumber.slice(0, 3) + '-' + phoneNumber.slice(3, 6) + '-' + phoneNumber.slice(6)).replace(/-+$/, '') : '';
  
  const handleDateChange = (newDate: Date, event: React.SyntheticEvent<any, Event>) => {
    setPurchaseDate(newDate);
    if (setDate) {
      setDate(newDate);
      }
      if (onChange) {
        onChange(newDate, event);
      }
  };
  
  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value } = event.target;
    if (!value) {
      setInputError(t('This field is required.'));
    } else {
      setInputError('');
    }
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (!validateEmail(email)) {
      setEmailInputError(t('Invalid email address'));
    } else {
      setEmailInputError(null);
    }
  };

  const plan = productDetails?.availablePlans?.find(plan => {
    return plan.planName === ratePlan;
  }); 
  // const ratePlanName = ratePlan;
  const MSRP = parseFloat(plan?.price.replace(/^\$/, ''));

  const isDisabled = !(vin && modelYear && modelName && trim && purchaseDate && firstName && lastName && email && phoneNumber && paymentSource && connectedService && salePrice && dealershipName && dealershipPACode && dealershipState && employeeName && employeeTitle && employeeEmail && employeePhoneNumber);
  
  useEffect(() => {
    setTrim('');
    setZipCode('');
    setSalePrice('');
    setEmployeeName('');
    setEmployeeTitle('');
    setEmployeeEmail('');
    setEmployeePhoneNumber('');
    setPurchaseDate(null);
    setPaymentSource('');
    setConnectedService('');
    setRatePlan('');
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('mousedown', () => setShowMenu(true));
    }
    return () => document.removeEventListener('mousedown', () => setShowMenu(false));
  }, [showMenu]);


  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <img
          src={`https://www.globalowneraem.ford.com/content/dam/loyalty/${
            isLAR()
              ? `lincoln/${country}/${languageCountry().toLowerCase()}/images/lincoln-rewards-logo.png`
              : `ford/${country}/${languageCountry().toLowerCase()}/images/fordPass-rewards.png`
          }`}
        />
        <div className={styles.title}>{t('Customer Authorization Form')}</div>
        <div className={styles.subtitle}>{t('One Time Connected Service Purchase')}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Vehicle Information')}</div>
          <div className={styles.columns}>
            <div className={styles.inputField}>
              {t('VIN')}
              <input className={styles.readOnly} type='text' value={vin} onBlur={handleBlur} onChange={(e: any) => setVin(e.target.value)} required />
              {inputError && <p className="error">{inputError}</p>}            
            </div>
            <div className={styles.inputField}>
              {t('Model Year')}
              <input className={styles.readOnly} type='text' value={modelYear} onBlur={handleBlur} onChange={(e: any) => setModelYear(e.target.value)} required />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Vehicle Line')}
              <input className={styles.readOnly} type='text' value={modelName} onBlur={handleBlur} onChange={(e: any) => setModelName(e.target.value)} required />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Trim (PEP)')}
              <input className={styles.newEntry} type='text' value={trim} onBlur={handleBlur} onChange={(e: any) => setTrim(e.target.value)} required />
              {inputError && <p className="error">{inputError}</p>}
            </div>
            <div className={styles.inputField}>
              {t('Purchase Date')}
              <br />
              <DatePickerWithLabel label='' id='purchaseDate' onChange={handleDateChange} selectedDate={purchaseDate} minDate={lastMonth} maxDate={today} />
            </div>
          </div>
        </div>
      <div className={styles.lineBreak}></div>
      <div className={styles.container}>
        <div className={styles.header}>{t('Customer Information')}</div>
        <div className={styles.columns}>
        <div className={styles.inputField}>
            {t('First Name')}
            <input className={styles.readOnly} type='text' value={firstName} onBlur={handleBlur} onChange={(e: any) => setFirstName(e.target.value)} required />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Last Name')}
            <input className={styles.readOnly} type='text' value={lastName} onBlur={handleBlur} onChange={(e: any) => setLastName(e.target.value)} required />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Email')}
            <input className={styles.readOnly} type='text' value={email} onBlur={handleEmailBlur} onChange={(e: any) => setEmail(e.target.value)} required />
            {emailInputError && <p className="error">{emailInputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Phone Number')}
            <input className={styles.readOnly} type='text' value={formatPhone(phoneNumber)} onBlur={handleBlur} onChange={(e: any) => setPhoneNumber(e.target.value.replace(/\D/g, '').substr(0, 10))} required />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {isCanada() ? t('Postal Code') : t('Zip Code')}
            <input className={styles.newEntry} type='text' minLength={5} maxLength={7} value={zipCode} onBlur={handleBlur} onChange={(e: any) => setZipCode(e.target.value)} required />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Payment Source')}
            <select 
              className={styles.dropdown}
              id='paymentSource'
              value={paymentSource}
              onBlur={handleBlur}
              onChange={(e: any) => setPaymentSource(e.target.value)}
              required
            >
              <option value=''>– {t('Select Payment Source')} –</option>
                <option value='cash'>{t('Cash')}</option>
                <option value='lease'>{t('Finance (Lease)')}</option>
                <option value='purchase'>{t('Finance (Purchase)')}</option>
            </select>
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Connected Service Information')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Connected Service')}
            <select 
                className={styles.dropdown} 
                id='connectedService' 
                value={connectedService} 
                onBlur={handleBlur} 
                onChange={(e: any) => setConnectedService(e.target.value)}
                required
              >
              <option value=''>– {t('Select Connected Service')} –</option>
              <option value={productDetails?.name}>{productDetails?.name}</option>
            </select>
            {inputError && <p className="error">{inputError}</p>}
         </div>
         <div className={styles.inputField}>
            {t('Connected Service Plan')}
            <select 
              className={styles.dropdown} 
              id='ratePlan'
              value={ratePlan}
              onBlur={handleBlur} 
              onChange={(e: any) => {setRatePlan(e.target.value); setConnectedServiceSelected(true);
              }}
              required
            >
              <option value=''>– {t('Select Service Plan')} –</option>
              {productDetails?.availablePlans
                .filter(plan => plan.financeable !== false && plan.price !== null)
                .map(plan => (
                  <option key={plan.planName} value={plan.planName}>
                    {plan.planName}
                  </option>
                ))
              }
            </select>
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer Selling Price')}
            <input 
              className={styles.newEntry} 
              type='text'
              placeholder={connectedServiceSelected && ratePlan ? `$ ${MSRP}` : '$'}
              value={salePrice} 
              onBlur={handleBlur} 
              onChange={(e: any) => setSalePrice(e.target.value)} 
              required 
            />
            {inputError && <p className="error">{inputError}</p>}
          </div>
        </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Dealer Information')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Dealership Name')}
            <input className={styles.readOnly} type='text' value={dealershipName} onBlur={handleBlur} onChange={(e: any) => setDealershipName(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer P&A Code')}
            <input className={styles.readOnly} type='text' value={dealershipPACode} onBlur={handleBlur} onChange={(e: any) => setDealershipPACode(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Dealer State')}
            <input className={styles.readOnly} type='text' value={dealershipState} onBlur={handleBlur} onChange={(e: any) => setDealershipState(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.container}>
        <div className={styles.header}>{t('Authorizing Dealer Employee')}</div>
        <div className={styles.columns}>
          <div className={styles.inputField}>
            {t('Full Name')}
            <input className={styles.newEntry} type='text' value={employeeName} onBlur={handleBlur} onChange={(e: any) => setEmployeeName(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Title')}
            <input className={styles.newEntry} type='text' value={employeeTitle} onBlur={handleBlur} onChange={(e: any) => setEmployeeTitle(e.target.value)} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Email')}
            <input className={styles.newEntry} type='text' value={employeeEmail} onBlur={handleEmailBlur} onChange={(e: any) => setEmployeeEmail(e.target.value)} />
            {emailInputError && <p className="error">{emailInputError}</p>}
          </div>
          <div className={styles.inputField}>
            {t('Phone Number')}
            <input className={styles.newEntry} type='text' value={formatPhone(employeePhoneNumber)} maxLength={12} minLength={12} onBlur={handleBlur} onChange={(e: any) => setEmployeePhoneNumber(e.target.value.replace(/\D/g, '').substr(0, 10))} />
            {inputError && <p className="error">{inputError}</p>}
          </div>
          </div>
      </div>
      <div className={styles.lineBreak} />
      <div className={styles.buttonBar}>
      <Link to='/dealer-auth-form' className={isDisabled ? styles.linkDisabled : styles.linkEnabled} > 
       <button className={cx(utils.buttonPrimary, styles.diableButton)} disabled={isDisabled}>
          {t('Continue to Dealer Authorization Form')}
        </button>
      </Link>
      </div>
    </div>
  );
};