import { APIHelper } from '../../models/api-helper';
import { post } from '../generic-service';
import ServiceHandler from '../service-handler';

export default class DsecReportDownloadService {
  public async downloadReport(data: any, apiHelper: APIHelper, errorMsg: string) {
    post('DSEC_REPORT_DOWNLOAD', null, data, data, null, apiHelper, ServiceHandler.ConfigService.DSEC_BASE_URL).then(response => {
      if (response?.status == 'error') {
        return apiHelper.displayErrorMsg({ title: apiHelper.t('Unable to Download'), message: errorMsg });
      }
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.reportType}_${data.month}_${data.year}_report.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
}
