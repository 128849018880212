import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './docusign-dialog.module.scss';
import { ModalDialog, Body, Buttons } from '../../common/modal-dialog/modal-dialog';
import { CtaButton } from '../../common/cta/cta';

interface Props {
    doClose?(): void;
}

export const DocuSignFailDialog = ({ doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);


  return (
    <ModalDialog title={t('DOCUSIGN_FAIL_TITLE')} doClose={doClose} width='30vw' height='14vw'>
        <Body>
            <div>{t('DOCUSIGN_FAIL_BODY')}</div>
        </Body>
        <Buttons>
            <CtaButton className={styles.button} label={t('Close')} onClick={() => {
              if (doClose) doClose();
            }} />
        </Buttons>
    </ModalDialog>
  );
};