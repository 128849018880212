import React, { useContext } from 'react';
import cx from '../../../utils/classnames';
import utils from '../../../utils/utils.module.scss';
import { RewardProgramChoice } from '../../sections/choice-reward-program/choice-reward-program';
import { PointsSection } from '../../sections/points-section/points-section';
import { ThreeColumnRow, TwoColumnRow, Column, DoubleColumn } from '../../common/column/column';
import { OffersSection } from '../../sections/offers-section/offers-section';
import { ShoppingCartSection } from '../../sections/shopping-cart-section/shopping-cart-section';
import { Header } from '../../common/header/header';
import { InputsContext } from '../../../context/inputs-context';
import { InputWithLabel } from '../../common/Input-with-label/input-with-label';
import { CustomerContext } from '../../../context/customer-context';
import { TranslationContext } from '../../../context/translations-context';
import { OffersRedeemableSection } from '../../sections/offers-redeemable-section/offers-redeemable-section';
import { filterOffers } from '../../../services/offers-service/offers-service';
import { CurrencyInput } from '../../common/formatted-input/formatted-input';

export const TabPartsView = () => {
  const [{ t }] = useContext(TranslationContext);
  const [{ invoiceNumber, invoiceAmount }, { setInvoiceNumber, setInvoiceAmount }, { validateInvoice }] = useContext(InputsContext);
  const [{ rewardProgram, offers }] = useContext(CustomerContext);

  return (
    <ThreeColumnRow>
      <DoubleColumn>
        <RewardProgramChoice uniqueID='TabPartsView' />
        <Header title={t('Invoice')} className={utils.section}>
          <TwoColumnRow>
            <Column className={utils.noPadding}>
              <InputWithLabel
                label={t('Invoice Number')}
                validator={validateInvoice}
                id='invoiceNumber'
                value={invoiceNumber}
                maxLength={6}
                onChange={e => setInvoiceNumber(e.target.value.replace(/\D/g, ''))}
              />
            </Column>
            <Column className={cx(utils.marginLeft30, utils.noPadding)}>
              <CurrencyInput label={t('Amount')} id='invoiceAmount' value={invoiceAmount} setValue={setInvoiceAmount} />
            </Column>
          </TwoColumnRow>
        </Header>

        <PointsSection />

        <Header title={t('Points Offers')} icon='tags' className={cx(utils.marginTop50)}>
          <OffersRedeemableSection offers={filterOffers(offers?.[rewardProgram].dro, 'XRA')} />
        </Header>
        <OffersSection
          title={`${rewardProgram == 'F' ? 'Ford ' : 'Lincoln'} ${t('Offers')}`}
          offers={filterOffers(offers?.[rewardProgram].drc, 'XRA')}
        />
      </DoubleColumn>
      <Column>
        <ShoppingCartSection title={t('Invoice Amount')} amount={invoiceAmount} validators={[validateInvoice]} />
      </Column>
    </ThreeColumnRow>
  );
};
