/* eslint-disable no-useless-escape */
import React from 'react';
import { FontAwesome } from '../../common/font-awesome/font-awesome';
import styles from './shopping-cart-section.module.scss';

interface ItemProps {
  children?: React.ReactNode;
  className?: string;
  icon?: string;
  desc: string;
  dollar?: string;

  remove?(): void;
}

export const CartItem = ({ icon, desc, dollar = '', children, remove, className }: ItemProps) => (
  <tr className={className}>
    {icon && (
      <td>
        <FontAwesome icon={icon} onClick={remove} />
      </td>
    )}
    <td colSpan={!icon ? 2 : undefined} className={styles.desc}>
      {desc}
    </td>
    <td className={styles.dollar}>{dollar}</td>
    <td className={styles.amount}>{children}</td>
  </tr>
);

export const CartMessage = ({ children }) => (
  <tr>
    <td colSpan={4} className={styles.desc}>
      {children}
    </td>
  </tr>
);

export const CartError = ({ err }) => (
  <tr className={styles.error}>
    <td>
      <FontAwesome icon='triangle-exclamation' />
    </td>
    <td colSpan={4}>{err}</td>
  </tr>
);
