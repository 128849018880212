import React, { useContext } from 'react';
import { TranslationContext } from '../../../context/translations-context';
import styles from './inactive-member.module.scss';

import { ModalDialog, Body } from '../../common/modal-dialog/modal-dialog';
import { QRCodeComponent } from '../../common/qr-code/qr-code';

interface Props {
  doClose?(): void;
}

export const InactiveLincolnDialog = ({ doClose }: Props) => {
  const [{ t }] = useContext(TranslationContext);

  // lar-en-US
  // https://www.lincoln.com/support/lincoln-way-app/lincoln-access-rewards/dashboard/


  const url = `https://www.lincoln.com/support/lincoln-way-app/lincoln-access-rewards/dashboard/`

  return (
    <ModalDialog title={t('QR_INACTIVE_LINC')} doClose={doClose} className={styles.modal}>
      <Body>
        <div className={styles.subtext}>{t('Scan QR Code')}</div>
        <div className={styles.QR}>
          <QRCodeComponent name={url} />
        </div>
      </Body>
    </ModalDialog>
  );
};
